
import { Component, Mixins } from 'vue-property-decorator';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';
import { namespace } from 'vuex-class';
import { StaticContentType } from '../enums/static-content-type.enum';
import { StaticContent } from '../models/static-contents/static-content.interface';
import { Homepage } from '../models/static-contents/homepage.interface';
import { translateApi } from '../utils/translate-api';
import { Banner } from '../models/static-contents/banner.interface';
import Course from '@/models/courses/course.class';
import { HomePageUsp } from '@/models/static-contents/homepage-usp.interface';
import HomePageUsps from '../components/HomePageUsps.vue';
import HighlightedCourses from '../components/HighlightedCourses.vue';
import CustomerReviews from '../components/CustomerReviews.vue';
import { UrlType } from '@/enums/url-type.enum';
import { Page } from '@/models/pages/page.class';
import CustomerReview from '@/models/customer-reviews/customer-review.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';

const staticContentsModule = namespace('staticContents');
const coursesModule = namespace('courses');
const pagesModule = namespace('pages');
const customerReviewsModule = namespace('customerReviews');
const appModule = namespace('app');

type HomeStaticContentType<T> = T;

@Component<Home>({
  components: { HomePageUsps, HighlightedCourses, CustomerReviews },
  filters: {
    translateApi,
  },
  metaInfo() {
    return {
      title: this.seoTitle,
      titleTemplate: undefined,
    };
  },
})
export default class Home extends Mixins(UsePageHeaderMixin) {
  @staticContentsModule.Action('fetchStaticContent')
  fetchStaticContent!: (type: StaticContentType) => Promise<StaticContent>;

  @staticContentsModule.Getter('getStaticContentByType')
  getStaticContentByType!: <T>(type: StaticContentType) => HomeStaticContentType<T>;

  @coursesModule.Action('fetchHighlightedCourses')
  fetchHighLightedCourses!: () => Course[];

  @coursesModule.Getter('highlightedCourses')
  courses!: Course[];

  @coursesModule.Action('fetchOne')
  fetchCourseById!: (id: string) => Course;

  @coursesModule.Action('clearOne')
  clearCourse!: () => void;

  @pagesModule.Action('fetchOne')
  fetchPageByIdOrSlug!: (idOrSlug: string) => Page;

  @pagesModule.Action('clearOne')
  clearPage!: () => void;

  @customerReviewsModule.Action('fetchUnpaginated')
  fetchCustomerReviews!: (params?: FetchAllParams) => CustomerReview[];

  @customerReviewsModule.Getter('all')
  customerReviews!: CustomerReview[];

  @appModule.Getter('windowWidth')
  viewWidth!: number;

  homepage: Homepage | null = null;
  banner: Banner | null = null;
  usp: HomePageUsp | null = null;

  get headerHtml() {
    return `<h2 class="text-white">${translateApi(
      this.banner?.title?.value,
      this.$i18n.locale,
    )}</h2>`;
  }

  get seoTitle() {
    if (this.homepage) {
      return translateApi((this.homepage as Homepage).seoTitle.value, this.$i18n.locale);
    }
    return '';
  }

  get highlightedCourses() {
    return this.courses;
  }

  async created() {
    await this.fetchHighLightedCourses();
    await this.fetchCustomerReviews();
    if (!this.getStaticContentByType(StaticContentType.Homepage)) {
      await this.fetchStaticContent(StaticContentType.Homepage);
    }
    if (!this.getStaticContentByType(StaticContentType.HomepageUsp)) {
      await this.fetchStaticContent(StaticContentType.HomepageUsp);
    }
    if (!this.getStaticContentByType(StaticContentType.Banner)) {
      await this.fetchStaticContent(StaticContentType.Banner);
    }
    if (!this.getStaticContentByType(StaticContentType.HomepageOffer)) {
      await this.fetchStaticContent(StaticContentType.HomepageOffer);
    }

    this.homepage = this.getStaticContentByType<Homepage>(StaticContentType.Homepage);
    this.banner = this.getStaticContentByType<Banner>(StaticContentType.Banner);
    this.usp = this.getStaticContentByType<HomePageUsp>(StaticContentType.HomepageUsp);

    if (this.banner?.url.urlType === UrlType.Course) {
      this.fetchCourseById(translateApi(this.banner.url.value, this.$i18n.locale));
    }

    if (this.banner?.url.urlType === UrlType.Page) {
      this.fetchPageByIdOrSlug(translateApi(this.banner.url.value, this.$i18n.locale));
    }
  }

  destroyed() {
    this.clearCourse();
    this.clearPage();
  }
}
