
import CustomerReview from '@/models/customer-reviews/customer-review.class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CustomerReviewItem from '../components/CustomerReviewItem.vue';

@Component({
  components: { CustomerReviewItem },
})
export default class CustomerReviews extends Vue {
  value = 0;
  @Prop({ type: Array, required: true })
  customerReviews: CustomerReview[] = [];

  @Prop({ type: Number, required: true })
  viewWidth;

  @Prop({ type: Boolean })
  isCourseDetail!: boolean;

  reviews: CustomerReview[] = [];

  @Watch('customerReviews', { immediate: true, deep: true })
  onValueChange(customerReviews) {
    if (this.$route.name === 'home') {
      const filteredResult: CustomerReview[] = customerReviews.filter(review => {
        return review.showOnHomePage == true;
      });
      this.reviews = filteredResult;
      this.progress();
      return;
    }
    this.reviews = [...customerReviews];
    this.progress();
  }

  responsive = {
    600: {
      perView: this.isCourseDetail ? 1 : 1,
      peek: { before: 0, after: 0 },
    },
    1003: {
      perView: this.isCourseDetail ? 1 : 1,
      peek: { before: 0, after: this.isCourseDetail ? 0 : 200 },
    },
    3000: {
      perView: this.isCourseDetail ? 1 : 2,
      peek: { before: 0, after: this.isCourseDetail ? 0 : 250 },
    },
  };

  progress(calculation?: string) {
    if (this.reviews && this.reviews.length) {
      const amount: number = 100 / this.reviews.length;
      let multiplier = 1;

      if (this.viewWidth < 1003) {
        multiplier = 2;
      }

      if (calculation === 'increment') {
        if (this.value === 100) {
          return;
        }
        this.value += amount;
        return;
      }
      if (calculation === 'decrement') {
        if (this.value <= amount * multiplier) {
          this.value = this.isCourseDetail ? amount : amount * multiplier;
          return;
        }
        this.value -= amount;
        return;
      }
      this.value = this.isCourseDetail ? amount : amount * multiplier;
      return this.value;
    }
  }
}
