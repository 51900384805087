
import { Component, Vue, Prop } from 'vue-property-decorator';
import { translateApi } from '@/utils/translate-api';

@Component({
  components: {},
  filters: {
    translateApi,
  },
})
export default class CustomerReviewItem extends Vue {
  @Prop({ type: Object })
  customerReview;
}
