
import { Component, Vue, Prop } from 'vue-property-decorator';
import { HomePageUsp } from '@/models/static-contents/homepage-usp.interface';
import HomePageUspItem from '../components/HomePageUspItem.vue';
import { translateApi } from '@/utils/translate-api';

@Component({
  components: { HomePageUspItem },
  filters: {
    translateApi,
  },
})
export default class HomePageUsps extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  usp!: HomePageUsp;

  get title() {
    return translateApi(this.usp?.title?.value, this.$i18n.locale);
  }
}
