
import { Component, Vue, Prop } from 'vue-property-decorator';
import CourseItem from '../components/CourseItem.vue';
import Course from '@/models/courses/course.class';
import { translateApi } from '@/utils/translate-api';

@Component({
  components: { CourseItem },
})
export default class HighlightedCourses extends Vue {
  @Prop({ type: Array, required: true })
  courses;

  responsive = {
    600: {
      perView: 1,
      peek: { before: 0, after: 0 },
    },
    1003: {
      perView: 1,
      peek: { before: 0, after: 100 },
    },
    3000: {
      perView: 1,
      peek: { before: 0, after: 350 },
    },
  };
  toCourse(index: number) {
    const course: Course = this.courses[index];
    if (course?.slug) {
      this.$router.push(`courses/${translateApi(course.slug, this.$i18n.locale)}`);
    }
  }
}
