
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class HomePageUspItem extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: String,
    required: true,
  })
  text!: string;
  @Prop({
    type: String,
    required: true,
  })
  iconName!: string;
}
